exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfahrt-js": () => import("./../../../src/pages/anfahrt.js" /* webpackChunkName: "component---src-pages-anfahrt-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaffee-js": () => import("./../../../src/pages/kaffee.js" /* webpackChunkName: "component---src-pages-kaffee-js" */),
  "component---src-pages-kaffeemaschinen-js": () => import("./../../../src/pages/kaffeemaschinen.js" /* webpackChunkName: "component---src-pages-kaffeemaschinen-js" */),
  "component---src-pages-kaffeeschulungen-js": () => import("./../../../src/pages/kaffeeschulungen.js" /* webpackChunkName: "component---src-pages-kaffeeschulungen-js" */),
  "component---src-pages-karriere-js": () => import("./../../../src/pages/karriere.js" /* webpackChunkName: "component---src-pages-karriere-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kundenservice-js": () => import("./../../../src/pages/kundenservice.js" /* webpackChunkName: "component---src-pages-kundenservice-js" */),
  "component---src-pages-lieferdienst-js": () => import("./../../../src/pages/lieferdienst.js" /* webpackChunkName: "component---src-pages-lieferdienst-js" */),
  "component---src-pages-tessmer-js": () => import("./../../../src/pages/tessmer.js" /* webpackChunkName: "component---src-pages-tessmer-js" */),
  "component---src-pages-zubehoer-js": () => import("./../../../src/pages/zubehoer.js" /* webpackChunkName: "component---src-pages-zubehoer-js" */)
}

